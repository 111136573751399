/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, ul {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, ul
%vertical-rhythm {
  margin-bottom: $spacing-unit / 3;
}

/**
 * Headings
 */
 h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}

h1, h2, h3, h4 {
  font-family: 'OCRATributePro', sans-serif;
  width: 75%;
}

h1 {
  padding-right: 4px;
  padding-top: 5px;
  line-height: 72px;
  font-size: 58px;
  word-break: break-word;
  hyphens: manual;
}

h1:last-of-type {
  margin-bottom: $spacing-unit;
}

h2 {
  font-size: 36px;
  line-height: 48px;
}

h3 {
  font-size: 30px;
  line-height: 44px;
  margin-top: 30px;
}

h4 {
  font-size: 24px;
  line-height: 30px;
}

p, ul {
  margin-bottom: $spacing-unit / 3;
  width: 80%;
  max-width: 1200px;
}

p a {
  text-decoration: none;
  border-bottom: 1px solid $brand-color-2;
  color: $brand-color-2;
}

p a:hover {
  border: none;
}

.dark .light a, a {
  color: $brand-color-2
}
a:hover {
  text-decoration: none;
}

.dark a,
.site-footer a {
  border-bottom: 1px solid #fff;
  text-decoration: none;
  color: #fff;
}

.intro h1 {
  word-break: keep-all;
}

.intro p {
  margin-bottom: .5em;
}

.intro p:last-of-type, .intro h2 {
  padding-bottom: 2em;
}

.container {
  width: 100%;
}

section {
  width: 100%;
  padding-top: 4rem;
  padding-left: $spacing-unit;
}

section .module {
  display: flex;
  flex-direction: column;
}

.dark {
  background-color: $brand-color;
  color: #fff !important;
}

.light h1,
.light h2, 
.light h3,
.light h4 {
  color: $brand-color;
}

#langselector {
  padding-right: 100px;
  text-align: right;
  font-size: .85em;
  color: #ccc;
}

#langselector a {
  text-decoration: none;
  color: $brand-color;
}

/**
 * Page content
 */
.page-content {
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

  h3 {
    @include relative-font-size(1.625);

    @include media-query($medium) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($medium) {
      @include relative-font-size(1.125);
    }
  }



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */

/**
 * Lists
 */
ul, ol {
  margin-left: 1.5em;
}

li {
  margin-bottom: .5em;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Page Links
 */
.page-link {
  color: $background-color;
  text-decoration: none;
  padding: 3px;
}

.trigger li {
  list-style: none;
  margin-top: 5px;
  display: inline-block;
  margin-right: $spacing-unit;
}


.page-wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$text-color};
    padding-right: 5px;
    vertical-align: text-top;
}

@media screen and (max-width: $medium) {
  p, ul {
    width: 90%;
  }
  section {
    width: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .collapsible.active .hideonactive {
    display: none;
  }

  #langselector {
    padding-right: 20px;
  }
}


@media screen and (max-width: $small) {
  section {
    padding-right: 20px;
    padding-left: 20px;
  }

  h1 {
    width: 100%;
  }

  .collapsible.active .hideonactive {
    display: none;
  }

  #langselector {
    padding-right: 20px;
  }
}


.shuffle {
  transition: background-color 500ms;
  hyphens: manual;
}
 

.shuffeling {
  border-bottom: 1px dotted #999;  
}


.mapshot {
  background-image: url("../img/map.png");
  background-repeat: no-repeat;
  background-position: 67% center;
  position: relative;
  display: block;
  border: none;
}

.mapshot.fullsize {
  display: block;
  width: 100%;
  max-width: 1058px;
  height: 300px;
  margin: 1em 0;
}

.mapshot .mapcredits {
  position: absolute;
  bottom: 2px;
  left: 2px;
  color: #fff;
  background-color: rgba(0,0,0,.2);
  font-family: Verdana, sans-serif;
  font-size: 10px;
}

.videocontainer {
  border: 20px solid #000;
  border-radius: 20px;
  background: #000;
  float: right;
  margin: 4em 4em 1em 1em;
  width: 280px;
}

.videocontainer video {
  width: 100%;
}

/*
@media screen and (max-width: $medium) {

} */

@media screen and (max-width: $small) {
  .videocontainer {
    float: none;
    width: 75%;
    margin: .75em auto;
  }
}

.collapsible p::after {
  content: ' >>';
  color: #ebdc86;
  cursor: pointer;
}

.collapsible.active p::after {
  content: ' <<'
}

p, li, td {
  hyphens: auto;
}

p {
  padding-top: .5rem;
}

strong {
  font-weight: 600;
}

.figure {
  border: 1px solid $brand-color;
}

.devops-wrapper {
  margin-bottom: 2em;
}

.stage img {
  max-height: 20vh;
  margin: 0 5px 5px 0;
  box-shadow: 0 0 5px #ccc;
  cursor: pointer;
}

.stage img:hover {
  box-shadow: 0 0 10px #000;
}

#fullscreenimage {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000088;
  overflow: auto;
  text-align: center;
  padding-top: 1em;
}

#fullscreenimage img {
  max-width: 70%;
  max-height: 90vh;
  box-shadow: 0 0 10px #000;
}

@media screen and (max-width: $small) {
  #fullscreenimage {
    padding-top: 2em;
  }
  #fullscreenimage img {
    max-width: 95%;
  }
}

#fullscreenimage .close {
  position: fixed;
  font-size: .85em;
  padding: 0 10px;
  bottom: 0;
  right: 0;
  background-color: $brand-color;
  color: white;
}

#fstitle {
  padding: 5px;
  color: black;
  background-color: white;
  font-size: .85em;
}

.disable-scroll {
  overflow-y: hidden;
}

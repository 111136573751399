canvas {
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#canvas-container {
  position: relative;
}


#terminalcontainer {
    height: 200px;
    background: $brand-color-light;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin-left: -50px;
    overflow-anchor: none;
}

@media screen and (max-width: $medium) {
    #terminalcontainer { 
      margin-right: -20px;
    }

    #tcontent {
        margin-right: 20px;
    }
}

#tcontent {
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 0;
}

#tcontent .prompt {
    color: #ebdc86;
}

#tcontent::after {
    content: '_';
    color: #ebdc86;
    animation: 1s blink step-end infinite;
}
  
@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #ebdc86;
    }
}

.team-list {
    display: flex;
    flex-direction: column;
}
.team-list-item {
    list-style: none;
    display: flex;
    margin-bottom: 15px;
}

.team-list-item .letter {
    width: 10%;
    text-align: center;
}

.team-list-item img {
    height: 50px;
    margin-right: 30px;
}

.team-list-item img:last-child {
    width: 65px;
}

@media screen and (max-width: $small) {
    .team-list-item {
        flex-direction: column;
    }
}
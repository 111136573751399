/**
 * Site header
 */
 .site-header {
  // Positioning context for the mobile navigation icon
  position: relative;
}
/**
 * Wrapper
 */
 .wrapper {
  padding-right: $spacing-unit;
  @extend %clearfix;

  @include media-query($medium) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
  }
}

.logo {
  height: 42px;
  margin: 33px auto 15px 40px;
}

.site-nav {
  float: right;
  font-size: 22px;
  margin: 35px auto 15px auto;
  pointer-events: none;


  .nav-trigger {
      display: none;
  }

  .trigger {
    pointer-events: all;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .trigger li a {
    font-family: "LetterGothicStd-Bold"
  }

  li.active a {
    color: $brand-color-2 !important;
    font-style: italic;
  }
}

@media screen and (max-width: $medium) {
  .site-nav {
    position: absolute;
    width: 50%;
    right: 0;
    top: 2px;
    text-align: right;
    z-index: 1;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      margin-right: 25px;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
      pointer-events: all;
    }
    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      text-align: center;
  
      > svg {
        fill: $brand-color-2;
      }
    }
  
    #close {
      display: none;
    }
  
    input ~ .trigger {
      clear: both;
      display: none;
    }
  
    input:checked ~ .trigger {
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
      margin-top: 55px;
      height: 100vh;
      background-color: $background-color;
    }
  
    .trigger li {
      margin-right: 25px;
      pointer-events: all;
    }
  
    .page-link {
      display: block;
      padding: 5px 10px;
      margin-bottom: 40px;
  
      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }

  .logo {
    margin-left: 30px;
  }
}

@media screen and (max-width: $small) {
  .logo {
    margin-left: 20px;
  }
  .site-nav {
    width: 100%;
  }
}

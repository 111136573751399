.portfolio-wrapper {
  width: 100vw;
}

.portfolio-wrapper:nth-child(odd) {
  background: $brand-color-light;
}

.portfolio-wrapper {
  padding-top: $spacing-unit / 2;
}

.portfolio-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: start;
  align-items: center;
}

.portfolio-section-wrapper p {
  width: auto;
}

.portfolio-section-col {
  display: flex;
  flex-direction: column;
}

.portfolio-section-col-1 {
  width: 25%;
  margin-left: $spacing-unit;
  word-break: break-word;
  align-items: center;
}

.portfolio-section-col-2 {
  width: 55%;
  margin-left: $spacing-unit;
}

.project-robots {
  height: 87px;
  margin-bottom: 26px;
}

.portfolio-title {
  text-align: center;
  color: #fff;
  margin-top: 0;
}

.logo-container {
  width: 100%;
  height: 50px; /* TODO: responsiveness */
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: contain;
  margin-bottom: 1em;
}

.project-content {
  display: none;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.projects-wrapper {
  margin-left: $spacing-unit;
  padding-top: 72px;
}

.projects-wrapper img {
  width: 90%;
  max-width: 600px;
}

@media screen and (max-width: $medium) {
  .portfolio-section-wrapper {
    flex-direction: column;
    padding-top: $spacing-unit;
  }
  .portfolio-section-col {
    align-self: center;
  }
  .portfolio-section-col-1 {
    margin-left: 0;
    width: 50%;
  }
  .portfolio-section-col-2 {
    margin-right: 30px;
    width: inherit;
    margin-left: 30px;
  }

  .projects-wrapper {
    margin-left: 30px;
  }
}

@media screen and (max-width: $small) {
  .projects-wrapper {
    margin-left: 30px;
  }

  .portfolio-section-col-1 {
    width: 90%;
  }

  .portfolio-section-col-2 {
    margin-right: 20px;
    margin-left: 20px;
  }
}
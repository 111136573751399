
.contact > p {
  font-size: $small-font-size;
}

@media screen and (max-width: $medium) {
  .nomap .site-footer .mapshot {
      display: none;
  }
}

@media screen and (max-width: $small) {
  .nomap .site-footer .mapshot {
      display: none;
  }
}
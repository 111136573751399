@charset "utf-8";

@font-face {
  font-family: 'OCRATributePro';
  font-weight: normal;
  src: url("/assets/OCRATributePro-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'OCRATributeProMono';
  font-weight: normal;
  src: url("/assets/OCRATributePro-RegularMono.otf") format("opentype");
}

@font-face {
  font-family: 'OCRATributePro-Bold';
  font-weight: bold;
  src: url("/assets/OCRATributePro-Bold.otf") format("opentype");
}
@font-face {
  font-family: 'LetterGothicStd';
  font-weight: normal;
  font-style: normal;
  src: url("/assets/LetterGothicStd.otf") format("opentype");
}
@font-face {
  font-family: 'LetterGothicStd-Bold';
  font-weight: bold;
  font-style: normal;
  src: url("/assets/LetterGothicStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: 'LetterGothicStd-BoldSlanted';
  font-weight: bold;
  font-style: italic;
  src: url("/assets/LetterGothicStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: 'LetterGothicStd-Slanted';
  font-style: italic;
  font-weight: normal;
  src: url("/assets/LetterGothicStd-Slanted.otf") format("opentype");
}

// Define defaults for each variable.

$base-font-family: 'LetterGothicStd', sans-serif;
$base-font-size:   18px !default;
$base-font-weight: 100;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     50px !default;

$background-color: #fff !default; 
$text-color: #000000 !default;
$brand-color:      #837870 !default;
$brand-color-2: #0068A6;
$brand-color-light: rgba(131,120,112,0.89);

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1200px !default;

$small:          600px !default;
$medium:        850px !default;



h3.devops {
  font-size: 1.1em;
  line-height: 27px;
}

.collapsible h4:first-child {
    cursor: pointer;
  }
  
  .light .collapsible h4::after {
    color: #ebdc86;
    content: ' >>';
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
  }
  
  .light .collapsible.active h4::after {
    color: #ebdc86;
    content: ' <<';
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
  }
  
  .devops-wrapper img {
    max-width: 100%;
  }
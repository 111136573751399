/**
 * Site footer
 */
 .site-footer {
  background-color: $brand-color;
  color: #fff;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: inherit;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin: 0 0 $spacing-unit / 2 $spacing-unit / 2;
  padding-top: $spacing-unit;
}

.footer-col-1 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
  background-color: antiquewhite;
  height: 250px;
  margin: 0 !important;
  padding: 0 !important;
}

.footer-col-2 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

@include media-query($medium) {
  .footer-col-1 {
    width: 100%;
  }
  .footer-col-2, .footer-col-3{
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

}

@include media-query($small) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
  .footer-col-1 {
    width: 100% !important;
  }
}

#scrolltop {
  position: fixed;
  bottom: 10px;
  right: 1em;
  width: 45px;
  height: 45px;
  font-size: 1.5em;
  text-align: center;
  background: #fff;
  border: 1px solid $brand-color;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s linear;
  cursor: pointer;
}

#scrolltop > svg {
  fill: $brand-color;
  height: 24px;
  margin: 10px 0 auto;
}

#scrolltop.show {
  display: block;
  opacity: 1;
  visibility: visible;
}

.disable-scroll #scrolltop.show {
  visibility: hidden;
} 